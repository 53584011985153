import React, { Fragment } from "react"
import Rafiki from "../../assets/images/vectors/rafiki3.svg"

const content = [
  "Complaints from consumers (B2C)",
  "Differences and disputes between businesses (B2B)",
  "Differences and disputes between businesses and its clients (both B2B and B2C)",
  "Supply chain problems (B2B)",
]

export default function AboutDetails() {
  return (
    <React.Fragment>
      <div id="about-details" className="about-details">
        <div className="about-details__container">
          <div className="about-details__left">
            <h2
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              className="about-details-text about-details-text--primary text-blue"
            >
              We resolve
            </h2>
          </div>
          <div className="about-details__right">
            {content.map((singleText, index) => {
              return (
                <Fragment>
                  <h3
                    data-sal="slide-left"
                    data-sal-delay="200"
                    data-sal-duration="700"
                    className="about-details-text about-details-text--secondary text-white"
                  >
                    {singleText}
                  </h3>
                  {index < content.length - 1 && (
                    <div className="divider"></div>
                  )}
                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
