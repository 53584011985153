import React from "react"

import IconOne from "../../assets/images/icons/icon8.svg"

import IconTwo from "../../assets/images/icons/icon9.svg"
import IconThree from "../../assets/images/icons/icon10.svg"

import LinkWhite from "../../assets/images/icons/linkArrowWhite.svg"

import { Link } from "gatsby"

const data = [
  {
    icon: IconOne,
    user: "Enterprise",
    background: "card--bg-grey",
    linkText: "Watch video",
    to: "https://www.youtube.com/watch?v=ysiTkYrSurQ&ab_channel=WebnyayPvtLtd",
  },
  {
    icon: IconTwo,
    user: "Individual",
    background: "card--bg-grey-2",
    linkText: "Watch video",
    to: "https://www.youtube.com/watch?v=ysiTkYrSurQ&ab_channel=WebnyayPvtLtd",
  },
  {
    icon: IconThree,
    user: "Professional",
    background: "card--bg-grey-3",
    linkText: "Request Brochure",
    to: "/cdr",
  },
]

export default function workingQuestions() {
  return (
    <div className="working-questions">
      {data.map((profile, index) => {
        return (
          <div
            className={`card--icon-card-2 ${profile.background} grow`}
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            <img src={profile.icon} alt="" />
            <div className="u-margin-top-medium"></div>
            <p className="text-white">
              {index === 2 ? "Are you a" : "Are you an"}
            </p>
            <p className="heavy-text text-white">{profile.user} ?</p>
            <div className="u-margin-top-small"></div>
            <Link to={profile.to} target="__blank">
              <LinkComp text={profile.linkText} />
            </Link>
          </div>
        )
      })}
    </div>
  )
}

const LinkComp = ({ text }) => {
  return (
    <div className="flex">
      <p className="products__link text-white">{text}</p>
      <div className="u-width-xsmall"></div>
      <img src={LinkWhite} alt="" />
    </div>
  )
}
