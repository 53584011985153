import React, { Fragment } from "react"
import { demoForm } from "../../constants"

const content = [
  {
    id: "01",
    title: "Speed",
    primary: "Courts take years to resolve disputes",
    secondary: "webnyay will do it in weeks",
  },
  {
    id: "02",
    title: "Location",
    primary: "Courts are stationary",
    secondary: "Webnyay is in your pocket",
  },
  {
    id: "03",
    title: "Convenience & Costs",
    primary:
      "In courts, you have to follow an archaic procedure that is formal, complex and requires physical court appearances. This is costly.",
    secondary:
      "Webnyay is informal. You just need to answer a few questions and upload your documents. Our fees is a fraction of what it would cost you to go to court",
  },
  {
    id: "04",
    title: "Judges",
    primary: "Judges are appointed by the Government",
    secondary:
      "At Webnyay, we work with industry experts and experienced professionals to resolve your dispute. These include retired judges, senior lawyers, former consumer court judges and banking ombudsmen.",
  },
  {
    id: "05",
    title: "Enforceability",
    primary: "Courts give you a decision that both parties honour. So do we!",
    secondary:
      "If the losing party fails to honour a decision, the winner can go to the execution court. You can do the same with a Webnyay arbitral award or settlement agreement.",
  },
  {
    id: "06",
    title: "Confidentiality",
    primary: "Court decisions are public and have open access.",
    secondary:
      "Webnyay is confidential and private (so dirty linen is not out in the public).",
  },
  {
    id: "07",
    title: "Service of court summons",
    primary:
      "In courts, you have to serve summons by post or publication (which is costly and time consuming)",
    secondary: "Webnyay, we do it for you for free and within minutes.",
  },
]

const PseudoCard = ({ content }) => {
  return (
    <div
      className="about-comparison__card"
      data-sal="slide-left"
      data-sal-delay="200"
      data-sal-duration="700"
    >
      <h3 className="count-text text-teal">{content.id}</h3>
      <div className="u-margin-top-small "></div>
      <h4 className="title text-teal">{content.title}</h4>
      <div className="u-margin-top-small"></div>

      <p className="content text-white">{content.primary}</p>
      <div className="u-margin-top-small"></div>

      <p className="content text-white">{content.secondary}</p>
    </div>
  )
}

export default function AboutComparison() {
  return (
    <React.Fragment>
      <div id="about-comparison" className="about-comparison">
        <div className="about-comparison__container">
          <div className="about-comparison__left">
            <h3
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              className="about-details-text about-details-text--primary text-teal"
            >
              Court V/s Webnyay
            </h3>
            <h3
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              className="about-details-text about-details-text--primary text-white"
            >
              Webnyay is leading the revolution in digitising dispute resolution
              and providing access to justice
            </h3>
          </div>
          <div className="about-comparison__right">
            <div className="about-comparison__child-container">
              {content.map((singleContent, index) => {
                return (
                  <Fragment>
                    <PseudoCard content={singleContent} />
                    {index === 1 && <div className="break"></div>}
                  </Fragment>
                )
              })}
            </div>
          </div>
        </div>
        <div className="u-margin-top-medium"></div>

        <div className="about-comparison__container2">
          <h4
            className="about-comparisonon__content text-white"
            data-sal="slide-down"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            We bring to you a full service offering for all of your hearing
            needs. We are experts in virtual hearing solutions for arbitrations,
            mediations, conciliations, adjudications, grievance redressal,
            investigations, ombudsman hearings as well as Lok Adalats and
            Courts.
          </h4>
          <div className="u-margin-top-large"></div>
        </div>

        <div className="about-comparison__button-container">
          <a target="__blank" href={demoForm} className="about-comparison__button-container__padding">
            <button
              id="teal-button"
              className="btn btn--transparent btn--large btn--rounded"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              Request a demo
            </button>
          </a>

          <a href="https://app.webnyay.in" target="__blank" className="about-comparison__button-container__padding">
            <button
              className="btn btn--contained  btn--large btn--rounded "
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              sign up
            </button>
          </a>
        </div>
        <div className="u-margin-top-large"></div>
      </div>
    </React.Fragment>
  )
}
