import React from "react"
import { Link } from "gatsby"

const cardData = [
  {
    heading: "WE WANT TO HEAR YOUR STORY",
    content: {
      __html:
        "We want to hear your story - what happened? Provide any documents that support your story. Don't worry - our Document Automation technology will assist in building your story and case",
    },
  },
  {
    heading: "Let's see what the other side says",
    content: {
      __html:
        "We've heard your story. Now, let's hear what the other side says. We will let you know what they say and pass on their documents to you. Again, our Document Automation technology will save the day and make it quicker for everyone",
    },
  },
  {
    heading: "Settle or Resolve your dispute",
    content: {
      __html:
        "Now that we have the entire story and both side's perspective, let's resolve the issues. The end result is either a mutually agreed settlement or a decision from a qualified expert <span>Happy days!</span>",
    },
  },
]

export default () => {
  const [state, setState] = React.useState(false)
  return (
    <div id="working" className="working">
      <h2
        className="main-heading text-blue main-heading--capital"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        How it works
      </h2>
      <div className="u-margin-top-medium"></div>
      <div className="working__container">
        {cardData.map((data, index) => {
          return (
            <div
              className="card--primary"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              <p className="numeric-heading">#{index + 1}</p>
              <div className="u-margin-top-small"></div>

              <p className="secondary-heading">{data.heading}</p>
              <div className="u-margin-top-small"></div>

              <p
                className="card-text"
                dangerouslySetInnerHTML={data.content}
              ></p>
            </div>
          )
        })}
      </div>
      <div className="u-margin-top-medium"></div>
    </div>
  )
}
