import React from "react"

import Layout from "../components/layout"
import Landing from "../components/aboutSections/aboutLanding"
import Details from "../components/aboutSections/aboutDetails"
import Vision from "../components/aboutSections/aboutVision"
import Questions from "../components/workingSections/workingQuestions"
import Working from "../components/homeSections/working"
import Features from "../components/productSections/productFeatures"

import Comparison from "../components/aboutSections/aboutComparison"

const AboutPage = () => (
  <Layout>
    <Landing />
    <Details />
    {/* <Vision /> */}
    <Features />
    <Comparison />
    <Questions />
    <Working />
  </Layout>
)

export default AboutPage
