import React from "react"
import Rafiki from "../../assets/images/vectors/rafiki3.svg"

export default function AboutLanding() {
  return (
    <React.Fragment>
      <div id="about-landing" className="about-landing">
        <div className="buffer only-short-height"></div>

        <div className="about-landing__container">
          <div className="about-landing__left">
            <h1
              className="about-landing-heading text-white"
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              With everything going digital, why bother going to court, doing
              paperwork and waiting years to get justice?
            </h1>
            <div className="u-margin-top-medium"></div>
            <h1
              className="about-landing-heading text-white"
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              At Webnyay, we believe in empowering you by allowing you to
              resolve your complaints, legal problems and disputes online
            </h1>
            <div className="u-margin-top-medium"></div>

            <a
              target="__blank"
              href="https://login.microsoftonline.com/common/oauth2/authorize?response_mode=form_post&response_type=id_token+code&scope=openid&msafed=0&nonce=8132d5a6-7241-4e44-bc69-54acc44b9976.637488084958923655&state=https%3A%2F%2Fforms.office.com%2FPages%2FDesignPage.aspx%3Ffragment%3DFormId%253DqjfY1zkcekWz6XUWzxZ2X7QtQAXgGixEsmvvze2zb0ZUNTg4OUFOM0NOTDRPMlNYWjBLRzVJUU5KUC4u%2526Token%253Deaf8dcf9c13443db8d8d900f90f58d66&client_id=c9a559d2-7aab-4f13-a6ed-e7e9c52aec87&redirect_uri=https%3a%2f%2fforms.office.com%2fauth%2fsignin"
            >
              <button
                className="btn btn--contained btn--large"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                Let's talk
              </button>
            </a>
            <div className="u-margin-top-small"></div>
          </div>
          <div className="about-landing__grow"></div>
          <div className="about-landing__right">
            <img
              src={Rafiki}
              alt="about-landing page main image"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="700"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
